<template>
  <div>
    <separator/>
    <draggable v-model="items" handle=".drag-handle" @start="start" @end="end">
      <t-c-priority-item v-for="(tc, index) in items" :key="tc.categoryId" :item="tc" :is-dragging="index === draggedIndex"/>
    </draggable>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Draggable from 'vuedraggable';
import TCPriorityItem from './TCPriorityItem';

export default {
  name: 'TCPriorities',
  components: {
    Separator: BaseUI.Separator,
    TCPriorityItem,
    Draggable,
  },
  data() {
    return {
      draggedIndex: null,
    };
  },
  props: {
    itemsDraft: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    items: {
      get() {
        return this.itemsDraft;
      },
      set(value) {
        this.$emit('priorities-changed', value);
      },
    },
  },
  methods: {
    start({ oldIndex }) {
      this.draggedIndex = oldIndex;
    },
    end() {
      this.draggedIndex = null;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
