<template>
  <div>
    <div class="margin-m ri-large-bold-body">
      גררו קטגוריה לשינוי הסדר במסך התזרים
    </div>
    <sticky-cta-page :cta-props="ctaProps" @click="save">
      <t-c-priorities :items-draft="prioritiesDraft" @priorities-changed="updatePrioritiesDraft"/>
    </sticky-cta-page>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import cashflowHistory from '@/store/utilities/cashflow-history';
import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import * as trackingCategoriesApi from '@/api/TrackingCategoriesApi';
import cashflowViewConsts from '@/constants/cashflow-view';
import Segment from '@/Segment';
import EventBus from '@/event-bus/event-bus';
import Toaster from '@/base-components/Toaster.vue';
import TCPriorities from './TCPriorities.vue';

export default {
  name: 'TCSettings',
  components: {
    TCPriorities,
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
  },
  data() {
    return {
      isLoading: false,
      prioritiesDraft: [],
      saveDisabled: true,
    };
  },
  async created() {
    EventBus.$emit('reset-scroll');
    Segment.trackUserGot('TrackingCategoriesSettingEntered');
    this.startContentLoading(this.$options.name);
    const budgetDate = cashflowHistory.getCurrentBudgetDate();
    await this.initBudgets({ budgetDate });
    const { order: currentOrder } = await trackingCategoriesApi.getTrackingCategoriesDisplaySettings();
    const categories = this.cashflowUITree[cashflowViewConsts.cashflowTypes.trackingCategory];
    this.prioritiesDraft = categories;
    if (currentOrder) {
      this.prioritiesDraft = _.sortBy(categories, category => currentOrder.indexOf(category.categoryId));
    }
    this.doneContentLoading(this.$options.name);
  },
  computed: {
    ...mapState('cashflowView', ['allCashflows', 'cashflowUITree']),
    ctaProps() {
      return {
        buttonProps: {
          title: 'שמירה',
          disabled: this.saveDisabled,
          loading: this.isLoading,
        },
      };
    },
  },
  methods: {
    ...mapActions('contentLoader', ['startContentLoading', 'doneContentLoading']),
    ...mapActions('cashflowView', ['initBudgets', 'resetBudgetTree', 'setTrackingCategoriesOrder']),
    updatePrioritiesDraft(priorities) {
      this.prioritiesDraft = priorities;
      this.saveDisabled = false;
    },
    async save() {
      Segment.trackUserInteraction('TrackingCategoriesSetting_SaveOrderClicked');
      this.isLoading = true;
      const newOrder = _.map(this.prioritiesDraft, item => item.categoryId);
      await trackingCategoriesApi.updateTrackingCategoriesDisplaySettings(newOrder);
      this.setTrackingCategoriesOrder(newOrder);
      await this.resetBudgetTree();
      EventBus.$emit('open-toaster', {
        component: Toaster,
        props: {
          text: 'ההגדרות עודכנו בהצלחה!',
        },
      });
      this.isLoading = false;
      this.saveDisabled = true;
    },
  },
};

</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';

</style>
