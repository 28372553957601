<template>
  <div class="plan-priority-item" :class="{ 'dragging': isDragging }">
    <div class="item-details" :aria-label="item.categoryName">
      <span class="name">{{ item.categoryName }}</span>
    </div>
    <icon class="drag-handle" role="button" icon-name="drag_indicator" size="24px" weight="400" :color="dragHandleColor"/>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'TCPriorityItem',
  components: {
    Icon: BaseUI.Icon,

  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    isDragging: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dragHandleColor: BaseUI.Colors.riseupBlack,
    };
  },
  methods: {
    closePopup() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors.scss';
@import '~@riseupil/base-ui/src/scss/typography';

.plan-priority-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 16px;
  height: 72px;
  border-bottom: 1px solid $riseup_gray_0;
  background: white;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  .item-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;

    .name {
      margin-right: 12px;
      font-size: $ri-font-size-20;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .drag-handle {
    padding: 16px 24px;
    cursor: grab;
  }

  &.dragging {
    box-shadow: 0 5px 10px rgba(186, 187, 189, 0.5);
    cursor: grabbing;
    .drag-handle {
      cursor: grabbing;
    }
  }
}
</style>
